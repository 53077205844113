import React from "react"
import { graphql } from "gatsby"


class Hotel extends React.Component {
    
    constructor(props){
        super(props);
    }  
  
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
        <div>
        </div>
    )
  }

  componentDidMount() {
      const script = document.createElement("script");

      var currency = "usd";
      var id = "5630";
      const query = this.props.location.search;
      if(query.length > 7){
        currency=query.substring(query.length-7,query.length-4)
        id=query.substring(query.length-4,query.length)
      }

      script.src = "//www.travelpayouts.com/blissey/scripts_en.js?categories=popularity&id="+id+"&type=compact&currency="+currency+"&host=search.hotellook.com&marker=219764.&limit=4&powered_by=false";
      script.charset = "utf-8";
      script.async = true;

      /*
      var test = document.createElement("p");
      test.innerHTML = currency+id;
      document.body.appendChild(test);
      */
     
      document.body.appendChild(script);
  }

}

export default Hotel

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`